import React, { useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import RBCarousel from "react-bootstrap-carousel";
import DatePicker from "react-datepicker";
import parser from 'html-react-parser';
import Loader from "react-loader-spinner";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import './App.css';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Equipment\nCheck-out",
            action: "co",
            actionName: "Check-out",
            asset: "",
            assetInfo: "",
            assetId: "",
            email: "",
            name: "",
            clientId: "",
            time: new Date(),
            expectedReturn: new Date(),
            lockField: false,
            modalShow: false,
            modalMessage: "",
            modalTitle: "",
            modalId: "",
            assetNote: "",
            display_time: "",
            display_expectedReturn: "",
            maxReturnDate: new Date(),
            retModalShow: false,
            retModalId: "retDate",
            statusUnlock: false,
            adminModalId: 'admin',
            adminModalShow: false,
            clientCOList: [],
            clientCODisplayList: "",
            coDeniedShow: false,
            coApprovedShow: false,
            loading: true,
        };
      
        this.actionToggle = this.actionToggle.bind(this);
        this.assetChange = this.assetChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.getClient = this.getClient.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.retModalClose = this.retModalClose.bind(this);
        this.updateReturnDate = this.updateReturnDate.bind(this);
        this.unlockApp = this.unlockApp.bind(this);
        this.adminModalSubmit = this.adminModalSubmit.bind(this);
        this.adminModalClose = this.adminModalClose.bind(this);
        this.clickRestart = this.clickRestart.bind(this);
        this.coDeniedModalClose = this.coDeniedModalClose.bind(this);
        this.coApprovedModalClose = this.coApprovedModalClose.bind(this);
    }
    nextP2Click = async() => {
        var lFlag1 = 0;
        var lFlag2 = 0;
        var lFlag3 = 0;
        if (this.state.email !== "") {
            if (this.state.name !== "") {
                lFlag1 = 1;
            } else {
                await this.getClient();
                if (this.state.name !== "") {
                    lFlag1 = 1;
                }
            }
        }
        if ((this.state.asset !== "") && (this.state.assetInfo !== "")) {
            lFlag2 = 1;
        }

        const listLayout = async(data) => {
            var d = new Date(parseInt(data.timestamp));
            let itemString = "<tr><td>" + d.toLocaleDateString() + "</td><td>" + data.asset_number + "</td></tr><tr class='co-list-item'><td colspan='2'>" + data.asset_name + "</td></tr>";
            itemString = this.state.clientCODisplayList + itemString;
            await this.setState({clientCODisplayList: itemString});
        }

//console.log("unlock - " + this.state.statusUnlock);
        if ((this.state.action === 'co') && (!this.state.statusUnlock)) {
            await this.checkoutStatus();
            if (this.state.clientCOList.length > 0) {
                await this.state.clientCOList.forEach(listLayout);
                if (this.state.clientCOList.length < 3) {
                    lFlag3 = 2;
                }
            } else {
                lFlag3 = 1;
            }
        } else {
            lFlag3 = 1;
        }

        if (lFlag1 === 0) {
            await this.setState({ modalId: "error", modalTitle: this.state.actionName, modalMessage: "Please enter your Email address and your Name.", modalShow: true})
            return false;    
        } else if (lFlag2 === 0) {
            await this.setState({ modalId: "error", modalTitle: this.state.actionName, modalMessage: "Please enter a valid Asset Number.", modalShow: true});
            return false;
        } else if (lFlag3 === 0) {
            await this.setState({ modalId: "coDenied", coDeniedShow: true});
            return false;
        } else if (lFlag3 === 2) {
            await this.setState({ modalId: "coApproved", coApprovedShow: true});
            return true;
        } else {
            return true;
        }
    }
    checkoutStatus = async() => {
        let url = "http://janerus.com:8086/client/coList/" + this.state.email;
        await fetch(url)
        .then(res => res.json())
        .then(result => {
//console.log(result);
            if (result !== null) {
                this.setState({ clientCOList: result });
            }
        });
    }
    actionToggle = async() => {
        let action = document.getElementById("aToggle").getAttribute("checked");
        if ((action === null) || (action === false)) {
            action = "ci";
            document.getElementById("aToggle").setAttribute("checked", "checked");
        } else if ((action === "checked") || (action === "true")) {
            action = "co";
            document.getElementById("aToggle").removeAttribute("checked");
        }
        this.setActionState(action);
        console.log("Toggle-" + action);
    }
    setActionState = async(action) => {
        let actionName = "";
        var coItems, i;
        if (action) {
            switch (action) {
                case 'co':
                    actionName = "Check-out";
                    await (this.setState({pageTitle: "Equipment\nCheck-out"}));
                    document.getElementById("slider").setAttribute("style", "background-color:rgba(225, 247, 165, .7);");
                    document.getElementById('uname').readOnly = false;
                    document.getElementById('email').readOnly = false;
                    document.getElementById('uname').classList.remove('ro-field');
                    document.getElementById('email').classList.remove('ro-field');
                    coItems = document.getElementsByClassName("checkout-items");
                    for (i = 0; i< coItems.length; i++) {
                        coItems[i].setAttribute("style", "display: flex");
                    }
                    break;
                case 'ci':
                    actionName = "Check-in";
                    await (this.setState({pageTitle: "Equipment\nCheck-in"}));
                    document.getElementById("slider").setAttribute("style", "background-color:rgba(225, 247, 165, .7);");
                    document.getElementById('uname').readOnly = true;
                    document.getElementById('email').readOnly = true;
                    document.getElementById('uname').classList.add('ro-field');
                    document.getElementById('email').classList.add('ro-field');
                    coItems = document.getElementsByClassName("checkout-items");
                    for (i = 0; i< coItems.length; i++) {
                        coItems[i].setAttribute("style", "display: none !important");
                    }
                    break;
                default:
            }
            await (this.setState({ action: action }));
            await (this.setState({ actionName: actionName }));
            console.log(action);
            console.log("trigger" + this.state.action + "--" + this.state.asset);
        }
    }
    assetChange = async() => {
        let asset = document.getElementById('asset').value.toUpperCase().replace(/^0+/, '');
        await this.setState({asset: asset});
        console.log("Change - " + this.state.asset);
        if (asset !== "") {
            if (asset.length >= 4) {
                await this.setState({ asset: asset });
                await this.getAsset();
            } else {
                await this.setState({ assetInfo: "" });
            }
/*
        } else {
                if (asset.substr(-1).match(/[0-9]/)) {
                    // with numbers only
                    if (asset.length < 6) {
                        if (asset.length === 5) {
                            asset = "0" + asset;
                        } else if (asset.length === 4) {
                            asset = "00" + asset;
                        }
                    }    
                } else {
                    // with character at the end
                    if (asset.length < 7) {
                        if (asset.length === 6) {
                            asset = "0" + asset;
                        } else if (asset.length === 5) {
                            asset = "00" + asset;
                        }
                    }
                }
            }
*/
        }
        if (this.state.asset !== "") {
//            await this.setState({ asset: asset });
//            this.getAsset();
            return true;
        } else {
//            await this.setState({ modalId: "error", modalTitle: this.state.actionName, modalMessage: "Please enter a valid Asset number.", modalShow: true})
            return false;
        }
    }
    getAsset = async() => {
        console.log("Change - " + this.state.asset);
        await this.setState({asset: this.state.asset.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')});
        var loadAsset = 0;
        if ((this.state.asset !== "") && (this.state.asset !== null) && (this.state.asset.search(" ") === -1)) {
            if (this.state.action === "ci") {
                let url = "http://janerus.com:8086/asset/ci/number/" + this.state.asset;
                await fetch(url)
                .then(res => res.json())
                .then(result => {
                    if (result !== null) {
                        if (result.is_found === "1") {
                            this.setState({ assetId: result.asset_id });
                            this.setState({ assetInfo: result.asset_info });
                            this.setState({ clientId: result.client_id });
                            this.setState({ email: result.client_email });
                            this.setState({ name: result.client_name });
//                            document.getElementById('asset').value = this.state.asset;
                            console.log("Info(CI)" + this.state.assetInfo);
                            loadAsset = 1;
                        } else {
                            this.setState({ modaiId: "error", modalTitle: this.state.actionName, modalMessage: "Equipment has not been checked out.\nPlease leave the equipment inside the bin.", modalShow: true});
                            loadAsset = 2;
                        }
                    } else {
                        this.setState({ modalId: "error", modalTitle: this.state.actionName, modalMessage: "Equipment not found. Please confirm you have entered the correct Asset Number.", modalShow: true});
                        this.setState({ assetInfo: "" });
                        this.setState({ assetId: "" });
                    }
                })
            } else {
                let url = "http://janerus.com:8086/asset/co/number/" + this.state.asset;
                await fetch(url)
                .then(res => res.json())
                .then(result => {
                    if (result !== null) {
                        this.setState({ assetInfo: result.asset_info });
                        this.setState({ assetId: result.asset_id });
//                        document.getElementById('asset').value = this.state.asset;
                        console.log("Info(CO)" + this.state.assetInfo);
                        loadAsset = 1;
                    } else {
                        this.setState({ modalId: "error", modalTitle: this.state.actionName, modalMessage: "Equipment not found. Please confirm you have entered the correct Asset Number.", modalShow: true});
                        this.setState({ assetInfo: "" });
                        this.setState({ assetId: "" });
                    }
                })
            }
        } else {
            this.setState({ modalId: "error", modalTitle: this.state.actionName, modalMessage: "Please enter a valid Asset Number.", modalShow: true});
            document.getElementById("asset").value = "";
        }

        return loadAsset;
    }
    emailChange = async() => {
    console.log("emailChange-" + this.state.email);
        var email = document.getElementById('email').value;
        await (this.setState({ email: email.toLowerCase() }));
//        if ((this.state.email !== "") && (this.state.name === "")) {
        if (this.state.email !== "") {
                console.log(this.state.email);
//            document.getElementById('overlay-wrapper').style.display = 'block';
            await this.getClient()
            .then(result => {
//                document.getElementById('overlay-wrapper').style.display = 'none';
                if (result !== null) {
//console.log("HERE?");
                }
            });
        }
    }
    nameChange = async() => {
        var name = document.getElementById('uname').value;
        await this.setState({ name: name });
        return true;
    }
    getClient = async() => {
        console.log("email" + this.state.email);
        if ((this.state.email !== "") && (this.state.email !== null)) {
            let url = "http://janerus.com:8086/client/email/" + this.state.email;
            await fetch(url)
            .then(res => res.json())
            .then(result => {
console.log("result");
console.log(result);
console.log(result.client_id);
//                if (result !== null) {
                if ((result.client_id !== null) && (result.client_id !== undefined)) {
//                    let fname = result.firstName;
//                    let lname = result.lastName;
//                    let name = fname + " " + lname;
                    let name = result.client_name;
                    this.setState({ name: name });
                    this.setState({ clientId: result.client_id });
                } else {
                    this.setState({ name: "" });
                    this.setState({ clientId: "" });
                }
            })
            .then(() => {
//                document.getElementById('spinner-name').setAttribute('style', 'visibility: hidden;');
                document.getElementById('uname').value = this.state.name;
            })
        }
        return this.state.name;
    }
    updateNote = async() => {
        var note = document.getElementById('asset-note').value;
        await this.setState({ assetNote: note });
        return true;
    }

    updateReturnDate = async(date) => {
        await this.setState({ retModalShow: true});
        date.setHours(23, 59, 59, 999);
        console.log(date + "===" + Date());
        await this.setState({ expectedReturn: date });
    }
/*
    retModalOpen = async() => {
        await this.setState({ retModalShow: true});
    }
*/
    retModalClose = async() => {
        await this.setState({ retModalShow: false});
    }

    adminModalClose = async() => {
        await this.setState ({ adminModalShow: false});
    }
    coDeniedModalClose = async() => {
        await this.setState({ clientCODisplayList: ""});
        await this.setState({ coDeniedShow: false});
    }
    coApprovedModalClose = async() => {
        await this.setState({ clientCODisplayList: ""});
        await this.setState({ coApprovedShow: false});
    }
    adminModalSubmit = async() => {
        let passcode = document.getElementById('passcode').value;
console.log("passcode - " + passcode);
        if (passcode === "1310131") {
console.log("match");
            await this.setState({statusUnlock: true});
            document.querySelectorAll('#return-date>div')[0].setAttribute('style', 'display: none;');
            document.querySelectorAll('#return-date>div')[1].setAttribute('style', 'display: block;');
            document.getElementById('btn-unlock').setAttribute("style", "background-color: rgba(225, 247, 165, .7)");
    }
        await this.setState({adminModalShow: false});
    }

    unlockApp = async() => {
//        console.log(document.querySelectorAll('#return-date > div'));
console.log(this.state.statusUnlock);
        if (this.state.statusUnlock === false) {
            await this.setState({ adminModalShow: true});
        } else {
            document.querySelectorAll('#return-date>div')[0].setAttribute('style', 'display: block;');
            document.querySelectorAll('#return-date>div')[1].setAttribute('style', 'display: none;');    
            document.getElementById('btn-unlock').setAttribute("style", "background-color: none");
            await this.setState({ statusUnlock: false });
        }
    }

    clickRestart = () => {
        window.location.reload();
    }
    checkUnlock = async() => {
        await this.setState({ adminModalShow: true});
    }
    // begin static functions
    modalClose = async() => {
        await this.setState({ modalShow: false });
        if ((this.state.modalId !== "next") && (this.state.modalId !== "error")) {
            window.location.reload();
        }
    }
    componentDidMount = async() => {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
        this.state.time.setHours(23, 59, 59, 999);
        await this.setState({ expectedReturn: this.state.time });

        setTimeout(() => {
        this.setState({ loading: false });
        }, 2000);
    }
    componentWillUnmount = () => {
        clearInterval(this.timerID);
    }
    tick = () => {
        let d = new Date();
        let m = 0;
        if (d.getDay() === 5) {
            m = new Date(Date.parse(d) + (86400000 * 3));
        } else {
            m = new Date(Date.parse(d) + 86400000);
        }
        let r = this.state.expectedReturn;
        let nowTime = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
        let expectedTime = r.getFullYear() + "-" + ("0" + (r.getMonth() + 1)).slice(-2) + "-" + ("0" + r.getDate()).slice(-2) + " (By end of the date)";
        this.setState({ time: d });
        this.setState({ display_time: nowTime });
        this.setState({ maxReturnDate: m });
        this.setState({ display_expectedReturn: expectedTime });
    }
    // end static functions

    Carousel = () => {
        const slider_ref = useRef(null);
        var _checkP1 = async() => {
            await this.assetChange().then(async() => {
                let btnNextP1 = document.getElementById('btn-next-1');
                btnNextP1.disabled = true;
                btnNextP1.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...';
                await this.getAsset().then(async(result) => {
//        console.log(result);
                    btnNextP1.disabled = false;
                    btnNextP1.innerHTML = 'Next';
                    if (((this.state.name === "") || (this.state.name === null)) && ((this.state.email !== null) && (this.state.email !== ""))) {
console.log("load Client");
                        await this.getClient();
                    }
                    if (result === 1) {
                        slider_ref.current.slideNext();
                    }
                })
            })
        }
        var _checkP2 = async() => {
            let btnNextP2 = document.getElementById('btn-next-2');
            btnNextP2.disabled = true;
            btnNextP2.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...';
            await this.nextP2Click().then(async(res) => {
                btnNextP2.disabled = false;
                btnNextP2.innerHTML = 'Next';
                if (res === true) {
                    slider_ref.current.slideNext();
                }
            })
        }
        var _checkDone = async() => {
            let url = "";
            console.log("Confirm");
            
//            await this.setState({ asset: "00" + this.state.asset });
            if (this.state.action === "co") {
                console.log("CO!!!");
                await this.setState({ modalId: "confirm", modalTitle: this.state.actionName, modalMessage: "Thank you for providing your information.\nPlease remember to check in the equipment when you return it.", modalShow: true});
                url = "http://janerus.com:8086/asset/checkout/" + this.state.asset;
            } else {
                console.log("CI!!!");
                await this.setState({ modalId: "confirm", modalTitle: this.state.actionName, modalMessage: "Thank you for checking in the equipment!", modalShow: true});
                url = "http://janerus.com:8086/asset/checkin/" + this.state.asset;
            }
            var clientIdTemp = "--";
            if (this.state.clientId !== '') {
                clientIdTemp = this.state.clientId;
            }
console.log("clientId-", clientIdTemp);
            let now = Date.now();
            let data = {"assetid": this.state.assetId, "assetinfo": this.state.assetInfo, "clientid": clientIdTemp, "clientemail": this.state.email, "clientname": this.state.name, "timestamp": now, "action": this.state.action, "expectedReturn": this.state.expectedReturn.getTime(), "note": this.state.assetNote};
            await fetch(url, {
                method: 'POST',
                mode: 'cors',
                header: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then (res => {
//console.log("res");
            })
    
//console.log("result: " + this.state.modalId + "-- status -- " + this.state.modalShow);
            if ((this.state.modalId !== "next") && (this.state.modalShow === true)) {
                setTimeout(() => {
//                    this.setState({ modalShow: false });
                }, 15000);
            }    
        }
        var clickN1 = async() => {
console.log("N1-Click");
console.log(this.state.email + "===" + this.state.name);
            await _checkP1();
        }
        var clickN2 = async() => {
console.log("N2-Click");
console.log(this.state.assetNote);
            await _checkP2();
        }
        var clickDone = async() => {
            await _checkDone();
        }
        var clickPrev = async() => {
            slider_ref.current.slidePrev();
        }

        const isWeekday = date => {
            const day = date.getDay();
            return day !== 0 && day !== 6;
        };
        const BtnInput = ({ value, onClick }) => (
                <Button className="custom-input" onClick={onClick}>
                    {value}
                </Button>
        )
        return (
            <RBCarousel className="w-100 h-100 m-0 p-0" autoplay={false} indicators={false} wrap={false} version={4} ref={slider_ref}>

                <div id="pane-1">
                    <div className="d-flex flex-row p-3"></div>
                    <div className="d-flex flex-row justify-content-center align-items-center" onClick={this.actionToggle}>
                        <div className="p-1"></div>
                        <div className="label">
                            <label className="form-check-label check-out">Check Out</label>
                        </div>
                        <div className="p-1"></div>
                        <div className="switch m-2">
                            <input type="checkbox" id="aToggle" tabIndex={-1}></input>
                            <span className="slider round" id="slider"></span>
                        </div>
                        <div className="p-1"></div>
                        <div className="label">
                            <label className="form-check-label check-in">Check In</label>
                        </div>
                        <div className="p-1"></div>
                    </div>
                    <div className="d-flex flex-row p-4"></div>
                    <div className="d-flex flex-row justify-content-center align-items-center mb-2" id="asset-block">
                        <div className="p-4"></div>
                        <div className="d-flex flex-row align-items-center asset-number text-field form-group m-0 p-0 w-100">
                            <label className="text-label" htmlFor="asset">Please enter Asset #</label>
                            <input type="text" inputMode="numeric" name="asset" id="asset" className="form-control-xlg form-control-lg w-100" onChange={this.assetChange} autoComplete="off" tabIndex={-1}></input>
                        </div>
                        <div className="p-4"></div>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center" id="asset-block">
                        <div className="p-4"></div>
                        <div className="asset-info text-field form-group mt-1 m-0 p-0 w-100">
                            <label className="text-label" htmlFor="asset-info">Item Description</label>
                            <textarea rows="2" cols="30" name="asset-info" id="asset-info" className="border rounded mt-1 p-1 w-100 ro-field" disabled value={this.state.assetInfo} tabIndex={-1} />
                        </div>
                        <div className="p-4"></div>
                    </div>
                    <div className="d-flex flex-row p-3"></div>
                    <div className="d-flex flex-row align-items-center checkout-items">
                        <div className="p-4"></div>
                        <div id="return-date" className="d-flex flex-row align-items-center return-date text-field md-4 form-group m-0 p-0 w-100">
                            <label className="text-label">Item Due Date</label>
                            <DatePicker id="datepicker-lock" className="datepicker form-control-xlg form-control-lg w-100" dateFormat="E, yyyy-MM-dd" selected={this.state.expectedReturn} maxDate={this.state.maxReturnDate} minDate={Date.now()} filterDate={isWeekday} onChange={this.updateReturnDate} withPortal customInput={<BtnInput />} />
                            <DatePicker id="datepicker-unlock" className="datepicker form-control-xlg form-control-lg w-100" dateFormat="E, yyyy-MM-dd" selected={this.state.expectedReturn} minDate={Date.now()} filterDate={isWeekday} onChange={this.updateReturnDate} withPortal customInput={<BtnInput />} />
                        </div>
                        <div className="p-4"></div>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center checkout-items">
                        <div className="p-4"></div>
                        <div className="asset-note text-field md-4 form-group m-0 p-0">
                            <label className="text-label">Note</label>
                            <textarea rows="3" cols="60" name="asset-note" id="asset-note" className="border rounded mt-1 p-1 w-100" value={this.state.assetNote} onChange={this.updateNote} tabIndex={-1} />
                        </div>
                        <div className="p-4"></div>
                    </div>
                    <div className="d-flex flex-row p-3"></div>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <div className="restart-field">
                            <button type="button" id="btn-restart" className="btn btn-xlg btn-lg btn-secondary class-prev" onClick={this.clickRestart} tabIndex={-1}>Start Over</button>
                        </div>
                        <div className="p-5"></div>
                        <div className="next-field">
                            <button type="button" id="btn-next-1" className="btn btn-xlg btn-lg btn-primary" onClick={clickN1} tabIndex={-1}>Next</button>
                        </div>
                    </div>
                </div>
                <div id="pane-2">
                    <div className="d-flex flex-row m-2 p-4"></div>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <div className="p-4"></div>
                        <div className="m-0 p-0">
                            <label className="text-label">Item Information</label>
                            <table id="asset-desc" className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th nowrap="true">Asset #</th>
                                        <td>{this.state.asset}</td>
                                    </tr>
                                    <tr>
                                        <th nowrap="true">Item description</th>
                                        <td>{this.state.assetInfo}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="p-4"></div>
                    </div>
                    <div className="d-flex flex-row p-4"></div>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <div className="p-4"></div>
                        <div className="email-field text-field form-group m-0 p-0 w-100">
                            <label className="text-label">Plesae enter your email address</label>
                            <input type="email" name="email" id="email" className="form-control-xlg form-control-lg w-100" onChange={this.emailChange} value={this.state.email} autoComplete="off" tabIndex={-1}></input>
                        </div>
                        <div className="p-4"></div>
                    </div>
                    <div className="d-flex flex-row p-3"></div>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <div className="p-4"></div>
                        <div className="uname-field text-field form-group m-0 p-0 w-100">
                            <label className="text-label">Name</label>
                            <input type="text" name="uname" id="uname" className="form-control-xlg form-control-lg w-100" onChange={this.nameChange} value={this.state.name} autoComplete="off" tabIndex={-1}></input>
                        </div>
                        <div className="p-4"></div>
                    </div>
                    <div className="d-flex flex-row p-5"></div>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <div className="prev-field">
                            <button type="button" id="btn-prev-2" className="btn btn-xlg btn-lg btn-secondary class-prev" onClick={clickPrev} tabIndex={-1}>Back</button>
                        </div>
                        <div className="p-5"></div>
                        <div className="next-field">
                            <button type="button" id="btn-next-2" className="btn btn-xlg btn-lg btn-primary" onClick={clickN2} tabIndex={-1}>Next</button>
                        </div>
                    </div>
                </div>
                <div id="pane-3">
                    <div className="d-flex flex-row p-4"></div>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <div className="summary-title text-field form-group m-0 p-0">
                            <h4>{this.state.actionName} Details</h4>
                        </div>
                    </div>
                    <div className="d-flex flex-row p-3"></div>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <div className="p-4"></div>
                        <div className="m-0 p-0">
                            <table id="summary" className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th scope="row" nowarp="true">Asset #</th>
                                        <td>{this.state.asset}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" nowarp="true">Item description</th>
                                        <td>{this.state.assetInfo}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" nowarp="true">Your email address</th>
                                        <td>{this.state.email}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" nowarp="true">Your name</th>
                                        <td>{this.state.name}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" nowarp="true">{this.state.actionName} time</th>
                                        <td>{this.state.display_time}</td>
                                    </tr>
                                </tbody>
                            </table>                            
                        </div>
                        <div className="p-4"></div>
                    </div>
                    <div className="d-flex flex-row p-3"></div>
                    <div className="d-flex flex-row justify-content-center align-items-center checkout-items">
                        <div className="return-time">
                            You agree to return the item by end of day on<br />{this.state.expectedReturn.toDateString()}
                        </div>
                    </div>
                    <div className="d-flex flex-row p-4"></div>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <div className="prev-field">
                            <button type="button" id="btn-prev-2" className="btn btn-xlg btn-lg btn-secondary class-prev" onClick={clickPrev} tabIndex={-1}>Back</button>
                       </div>
                        <div className="p-5"></div>
                        <div className="next-field">
                            <button type="button" id="btn-confirm" className="btn btn-xlg btn-lg btn-primary" onClick={clickDone} tabIndex={-1}>Confirm</button>
                        </div>
                    </div>
                </div>
            </RBCarousel>
        );
    }
    render() {
        let content = 
            <div>
            <Modal show={this.state.modalShow} onExited={this.modalClose} centered modalid={this.state.modalId}>
            <Modal.Header>
                <Modal.Title>{this.state.modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body><Container>{this.state.modalMessage}</Container></Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.modalClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={this.state.retModalShow} centered modalid={this.state.retModalId} onHide={this.retModalClose}>
            <Modal.Body>
                <Container>
                Please note that all equipment is for single day rental.<br /><br />If there are special situations you need to possess the equipment for longer period, please contact the Tech office.
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.retModalClose}>Close</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={this.state.adminModalShow} centered modalid={this.state.adminModalId} onHide={this.adminModalClose} dialogClassName="admin-modal">
            <Modal.Body>
                <Container>
                    <div className="d-flex flex-row justify-content-center align-items-center pt-5 pb-5 cust-border-bottom">
                        <Button variant="secondary" onClick={this.clickRestart} className="admin-restart">Start Over</Button>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center pt-5 pb-5">
                        <div className="p-1"></div>
                        <div className="m-0 p-0">
                            <input type="password" inputMode="numeric" title="Enter Passcode" name="passcode" id="passcode" placeholder={"Passcode"} className="form-control-xlg form-control-lg w-100" autoComplete="off" tabIndex={-1} />
                        </div>
                        <div className="p-3"></div>
                        <div className="m-0 p-0">
                            <Button className="admin-submit" variant="primary" onClick={this.adminModalSubmit}>Submit</Button>
                        </div>
                        <div className="p-1"></div>
                    </div>
                </Container>
            </Modal.Body>
        </Modal>
        <Modal show={this.state.coDeniedShow} centered modalid="coDenied" onHide={this.coDeniedModalClose}>
            <Modal.Header>
                <Modal.Title>Maximum checkout items reached!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    Please return the following items before checking out another item.
                    <table className="co-list">
                        <thead>
                            <tr><th>Checkout Date</th><th>Asset Number</th></tr>
                            <tr><th colSpan="2">Asset Name</th></tr>
                        </thead>
                        <tbody>
                        {parser(this.state.clientCODisplayList)}
                        </tbody>
                    </table>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.coDeniedModalClose}>Close</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={this.state.coApprovedShow} centered modalid="coApproved" onHide={this.coApprovedModalClose} dialogClassName="colist-modal">
            <Modal.Header>
                <Modal.Title>Reminder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    You still have the following unreturned items. Please return the item at your earliest convenience.
                    <table className="co-list">
                        <thead>
                            <tr><th>Checkout Date</th><th>Asset Number</th></tr>
                            <tr><th colSpan="2">Asset Name</th></tr>
                        </thead>
                        <tbody>
                        {parser(this.state.clientCODisplayList)}
                        </tbody>
                    </table>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.coApprovedModalClose}>Close</Button>
            </Modal.Footer>
        </Modal>

        <div className="d-flex flex-column align-items-center h-15 mt-0 ml-2 mr-2 mb-2 p-0">
            <div className="appLock" onClick={this.unlockApp}>
                <div className="btn-unlock" id="btn-unlock">&nbsp;</div>
            </div>
            <div className="d-flex flex-row h-100 w-100 justify-content-center align-items-center">
                <div className="d-flex justify-content-center flex-grow-1">
                    <h2 className="title" id="title">{this.state.pageTitle}</h2>
                </div>
            </div>
        </div>
        <div id="overlay-wrapper">
            <div className="overlay-loading h-100 w-100 d-flex -flex-row justify-content-center align-items-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div className="d-flex flex-row align-items-center h-flex mt-2 ml-5 mr-5 mb-5 p-0 border border-secondary rounded-xlg border-color pane">
            <this.Carousel />
        </div>
        </div>

        let loading = 
            <div className="d-flex flex-row justify-content-center align-items-center loading-screen">
                <Loader type="BallTriangle" height={200} width={200} color="rgb(190, 208, 115)" />
            </div>

        let display = "";
        if (this.state.loading) {
            display = loading;
        } else {
            display = content;
        }
        
        return (
            <div className="App container-fluid p-3 m-0 h-100">
                {display}
            </div>
        );
    }
}
    
export default App;